import React from 'react'
import { Container, Link as A } from '@hackclub/design-system'
import Layout from 'components/Layout'
import { Link } from 'gatsby'
import Nav from 'components/Nav'
import Start from 'components/Start'
import Footer from 'components/Footer'
import ImgGallery from '../components/Gallery/Gallery'
import Social from '../components/Socialicons'
import { graphql } from 'gatsby'
import { Ultraline, GalleryHeader } from '../components/Content'

A.link = A.withComponent(Link)

const title = 'Our Gallery - Coding Sastra'

export default ({ data }) => (
  <Layout
    title={title}
    desc={data.site.siteMetadata.description}
    keywords={data.site.siteMetadata.keywords}
    path="/gallery/"
  >
    <Nav />
    <Social />
    <GalleryHeader>
      <Container width={1} maxWidth={56} py={[6]} px={3} align="left">
        <Ultraline children="Our " />
        <Ultraline children="Gallery" />
      </Container>
    </GalleryHeader>
    <ImgGallery />
    <Start mt={3} />
    <Footer mt={5} />
  </Layout>
)

export const query = graphql`
  query galleryMetaData {
    site {
      siteMetadata {
        description
        keywords
      }
    }
  }
`
