import React, { useState } from 'react'
import Gallery from 'react-grid-gallery'
import { Box, Container, Text, Flex } from '@hackclub/design-system'
import { graphql, useStaticQuery } from 'gatsby'

const ALL_GALLERY = graphql`
  query allGallery {
    allContentfulGallery {
      nodes {
        id
        title
        photos {
          file {
            url
          }
        }
      }
    }
  }
`

const ImgGallery = () => {
  const data = useStaticQuery(ALL_GALLERY)
  let [albums, setAlbums] = useState(data.allContentfulGallery.nodes)

  return (
    <>
      <Flex flexDirection="column" bg="white" py={[2, 2]}>
        {albums.map(album => {
          let albumPhotos = album.photos.map(photo => {
            return {
              src: photo.file.url,
              thumbnail: photo.file.url,
              thumbnailHeight: 252,
              thumbnailWidth: 400
            }
          })
          return (
            <Container maxWidth={60} align="center" key={album.id}>
              <Box bg="white" mb={4}>
                <Text
                  color="fuschia.5"
                  fontSize={5}
                  mb={3}
                  px={[2, 2]}
                  align="left"
                  mt={5}
                >
                  {album.title}
                </Text>
                <Box ml={3} mr={3}>
                  <Gallery images={albumPhotos} />
                </Box>
              </Box>
            </Container>
          )
        })}
      </Flex>
    </>
  )
}

export default ImgGallery
